<template>
  <div>
    <NavBar :class="{ 'nav-back': navBackColor }" :iconColor="'#fff'">
      <div slot="title" class="nav-img-box">
        <img src="~@/assets/seckill/seconds_kill_img.png" />
      </div>
    </NavBar>
    <div class="page-layout">
      <van-swipe
        vertical
        style="height: 34px"
        :show-indicators="false"
        autoplay="1500"
        :touchable="false"
      >
        <van-swipe-item v-for="(user, u_index) in userList" :key="u_index">
          <div class="order-box">
            <img :src="user.head_img" class="head-img" />
            <span class="name">{{ user.nickname }}</span>
            <span>正在下单</span>
          </div>
        </van-swipe-item>
      </van-swipe>
      <!-- 爆款 -->
      <div class="popularity-card">
        <div class="title-box">
          <van-icon name="fire" color="#f3260b" size="24" />
          <span class="card-name">秒杀爆款</span>
        </div>
        <div class="popularity-goods">
          <div
            class="goods-box"
            @click="goToUrl(item.id, item.goods_id)"
            v-for="item in goodsList"
            :key="item.id"
          >
            <div class="popularity-goods-img">
              <img :src="item.cover_img" />
              <div class="price">
                <span>￥</span>
                <span class="integer">{{ toInteger(item.price) }}</span>
                <span>{{ twoFloating(item.price) }}</span>
              </div>
            </div>
            <div class="be-being">正在疯抢</div>
          </div>
        </div>
      </div>
      <!-- 秒杀时间段 -->
      <div class="time-card">
        <div class="title-img-box">
          <img src="~@/assets/seckill/seconds_kill.png" />
        </div>
        <div class="start-time">
          <div
            class="status-time"
            v-for="(item, index) in timeList"
            :key="index"
            @click="onPrepare(index)"
          >
            <span class="time" :class="{ color: num === index }">
              {{ item.hour }}
            </span>
            <div :class="{ 'back-color': num === index }" class="status">
              {{ time >= item.hour_time ? "抢购中" : "准备开抢" }}
            </div>
            <!-- <div v-else class="status" @click="onPrepare(index)">准备开枪</div> -->
          </div>
        </div>
      </div>
      <!-- 分类栏 -->
      <van-tabs
        v-model="active"
        animated
        @click="ontabs"
        line-width="40"
        ref="touch"
      >
        <van-tab
          v-for="(item, index) in classList"
          :key="index"
          :name="index"
          :title="item.class_name"
          :title-class="[{ 'text-weight': index === i }, 'text-size']"
        >
          <ListCard
            v-if="i >= 0"
            ref="goodsItem"
            :classId="classId"
            :groupId="groupId"
            @touchClick="touchClick"
          ></ListCard>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import ListCard from "./components/list-card";
import NavBar from "@/layout/nav-bar/nav-bar";
import {
  getGroupGoodsClass,
  getGroupList,
  getSeckillUser,
  getSeckillList,
} from "@/api/seckill";
import tools from "@/common/js/tools";

let currentFun = null;
export default {
  name: "seckill-time",
  components: {
    NavBar,
    ListCard,
  },
  data() {
    return {
      active: 0,
      num: 0, // 控制-抢购时间段-的样式
      i: -1,
      time: "",
      timer: "",
      startTimeArr: [],
      navBackColor: false,
      classList: [{ id: 0, class_name: "精选" }],
      timeList: [],
      goodsList: [],
      userList: [],
      currentTime: 0,
      classId: 0,
      groupId: 0,
    };
  },
  async mounted() {
    addEventListener("scroll", this.handleScroll);
    this.currentTime = Date.parse(new Date()) / 1000;
    if (currentFun === null) {
      let _that = this;
      currentFun = setInterval(function () {
        ++_that.currentTime;
      }, 1000);
    }
    //--------------------业务代码-------------------------------------------------------
    // let startTimeArr = [];
    const that = this;
    this.timer = setInterval(() => {
      that.time = Math.trunc(Date.now() * 0.001);
    }, 1000);
    // startTimeArr = await this.getTimeList();
    // console.log(startTimeArr);
    this.getTimeList();
    this.getSeckillList();
    this.getSeckillUser();
  },
  // 暂时先别删---------------
  // watch: {
  //   time(newVal, oldVale) {
  //     console.log("新", newVal, "---------旧", oldVale);
  //     this.time = newVal;
  //   },
  // },
  methods: {
    touchClick() {
      this.getTimeList();
    },
    goToUrl(seckillId, goodsId) {
      this.$router.push({
        name: "seckill-details",
        query: {
          seckillId: seckillId,
          goodsId: goodsId,
          type: 4,
        },
      });
    },
    async getTimeList() {
      // this.time = Math.trunc(Date.now() * 0.001);
      // let hourTimeArr = [];
      const res = await getGroupList();
      // res.data.forEach((item) => {
      //   hourTimeArr.push(item.hour_time);
      // });
      if (res.code * 1 === 1) {
        this.timeList = res.data;
        if (this.timeList.length > 0) {
          this.onPrepare(0);
        }
      }
      // return hourTimeArr;
    },
    async getGroupGoodsClass() {
      const res = await getGroupGoodsClass({ group_id: this.groupId });
      if (res.code * 1 === 1) {
        this.classList = res.data;
        this.i = 0;
      }
    },
    clickClass(index) {
      this.active = index;
    },
    onPrepare(index) {
      this.num = index * 1;
      this.i = 0;
      this.classId = 0;
      this.groupId = this.timeList[index].id;
      this.getGroupGoodsClass();
      this.$refs.touch.scrollTo(0);
    },
    ontabs(index) {
      this.i = index;
      this.classId = this.classList[index].id;
    },
    handleScroll() {
      const scrollTop = window.pageYOffset;
      scrollTop >= 20
        ? (this.navBackColor = true)
        : (this.navBackColor = false);
    },
    async getSeckillList() {
      const res = await getSeckillList({ page_num: 3 });
      this.goodsList = res.data.list;
    },
    async getSeckillUser() {
      const res = await getSeckillUser();
      this.userList = res.data;
    },
    toInteger(num) {
      // 取整
      let toNum = "";
      toNum = parseInt(num);
      return toNum;
    },
    twoFloating(num) {
      // 获取两位小数
      return tools.twoFloating(num);
      // let price = "";
      // price = Math.floor(num * 100) / 100;
      // price = String(price).split(".")[1];
      // if (price !== undefined && price.length === 1) {
      //   price = `.${price}0`;
      // } else {
      //   price === undefined ? (price = ".00") : (price = `.${price}`);
      // }
      //
      // return price;
    },
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  destroyed() {
    // 销毁滚动事件
    document.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="less" scoped>
.nav-layout {
  padding: 12px;
}
img {
  width: 100%;
  display: block;
}

.nav-back {
  background: linear-gradient(to right, #fb4e66, #f72821);
}

.nav-img-box {
  width: 104px;
}

.page-layout {
  padding: 56px 12px 12px 12px;
  min-height: 100vh;
  background: url("~@/assets/seckill/background_1.png") no-repeat top/100%;
  .order-box {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
}
.head-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-shadow: 0px 0px 6px rgba(255, 0, 0, 0.63);
}
.name {
  padding: 0 10px;
}

// 秒杀爆款
.popularity-card {
  background-color: #fff;
  border-radius: 16px;
  padding: 12px;

  .title-box {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 18px;

    .card-name {
      padding-left: 4px;
    }
  }

  .popularity-goods {
    padding-top: 12px;
    display: flex;
    justify-content: space-around;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .goods-box {
      width: 116px;
      .popularity-goods-img {
        position: relative;
        width: 116px;
        height: 116px;
        img {
          width: 116px;
          height: 116px;
          border-radius: 6px 6px 0 0;
        }
        .price {
          position: absolute;
          bottom: -14px;
          left: 50%;
          transform: translateX(-50%);
          width: 91px;
          height: 32px;
          background: #ffffff;
          opacity: 0.9;
          border-radius: 6px;
          color: #ed301d;
          font-size: 14px;
          text-align: center;
          line-height: 32px;
          .integer {
            font-size: 18px;
          }
        }
      }

      .be-being {
        font-size: 14px;
        text-align: center;
        padding: 14px 0 0 0;
      }
    }
  }
}

// 秒杀时间段
.time-card {
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title-img-box {
    width: 48px;
    flex-shrink: 0;
  }

  .start-time {
    flex: 80%;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    overflow: auto;
    padding-left: 12px;

    &::-webkit-scrollbar {
      display: none;
    }

    .status-time {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-shrink: 0;
      padding: 0 14px;
      width: 68px;

      .time {
        font-size: 22px;
        padding-bottom: 4px;
      }

      .status {
        text-align: center;
        font-size: 14px;
        color: #6f6f6f;
        padding: 2px 0;
        width: 100%;
      }

      .color {
        color: #ed301d;
      }

      .back-color {
        transition: all 0.5s;
        background: linear-gradient(to right, #ef9a26, #f41010);
        color: #fff;
        border-radius: 99px;
        padding: 2px 0;
      }
    }
  }
}

// 秒杀商品分类
// 秒杀商品分类--vant 组件样式
/deep/ .van-tabs__nav {
  border-radius: 16px 16px 0 0;
}

/deep/ .text-size {
  font-size: 16px;
  color: #6f6f6f;
}

/deep/ .text-weight {
  color: #ed301d;
}
</style>
