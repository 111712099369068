<template>
  <div>
    <div
      class="goods-list-card"
      :class="{ 'card-border': index === 0 }"
      v-for="(item, index) in goods_list"
      :key="index"
      @click="
        goToUrl(item.id, item.goods_id, item.sales, item.stock, item.is_remind)
      "
    >
      <div class="goods-img">
        <img :src="item.cover_img" alt="" />
      </div>
      <div class="detail-info">
        <div class="goods-name">{{ item.good_name }}</div>
        <!-- 精度条-与-限时抢-交换显示 -->
        <div v-if="timer - item.hour_time >= 0">
          <van-progress
            :show-pivot="false"
            :percentage="
              item.stock * 1 <= 0
                ? 0
                : (item.sales / item.stock) * 100 > 100
                ? 100
                : (item.sales / item.stock) * 100
            "
            color="#ee0a24"
            stroke-width="8"
          />
          <div class="progress">
            <span style="color: #fd6d1f">正在疯抢</span>
            <span style="color: #6f6f6f">已抢{{ item.sales }}件</span>
          </div>
        </div>
        <!-- 限时抢-与-精度条-交换显示 -->
        <div class="time-limit" v-else>
          <span>限时抢</span>
          <div class="lightning-img">
            <img src="~@/assets/seckill/lightning-icon.png" alt="" />
          </div>
        </div>
        <div class="price">
          <div>
            <span class="present-price">
              <span>￥</span>
              <span class="to-integer">{{ toInteger(item.price) }}</span>
              <span>{{ twoFloating(item.price) }}</span>
            </span>
            <span class="original-price">
              ￥{{ twoNum(item.original_price) }}
            </span>
          </div>
          <!-- v-if="currentItemTime - item.hour_time <= 0" -->
          <van-button
            type="danger"
            round
            size="small"
            color="#AAAAAA"
            disabled
            v-if="item.sales >= item.stock"
          >
            已售罄
          </van-button>
          <van-button
            type="danger"
            round
            size="small"
            color="linear-gradient(to right, #FC5656, #F40909)"
            v-else-if="timer >= item.hour_time"
          >
            立即抢购
          </van-button>
          <div v-else>
            <van-button
              type="danger"
              round
              size="small"
              color="linear-gradient(to right, #FCA83A, #FE9001)"
              v-if="item.is_remind === 0"
              @click="reserveBtn(item.id, index)"
              @click.stop
            >
              预约提醒
            </van-button>
            <van-button
              type="danger"
              round
              plain
              size="small"
              color="#EE9B3C"
              v-else
            >
              预约成功
            </van-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSeckillList, setRemind } from "@/api/seckill";
import { Toast } from "vant";
import tools from "@/common/js/tools";
export default {
  name: "seckill-group-list",
  props: ["classId", "groupId"],
  data() {
    return {
      show: true,
      currentItemFun: null,
      finished: false,
      goods_list: [],
      timer: "",
      page: 1,
      total: 100,
      isNext: false,
      isAjax: false,
      currentItemTime: 0,
    };
  },
  watch: {
    "_props.groupId": function () {
      this.reloadGoods();
    },
    "_props.classId": function () {
      this.reloadGoods();
    },
  },
  mounted() {
    this.timer = Math.trunc(Date.now() * 0.001);
    const that = this;
    setTimeout(() => {
      that.getGoodsList();
      if (that.currentItemFun === null) {
        let _that = that;
        // this.currentItemFun = setInterval(function () {
        //   ++_that.currentItemTime;
        // }, 1000)
      }
    }, 500);
    setInterval(() => {
      ++that.timer;
    }, 1000);
  },
  methods: {
    goToUrl(seckillId, goodsId, sales, stock, isRemind) {
      console.log("父组件isRemind-----", isRemind);
      this.$router.push({
        name: "seckill-details",
        query: {
          seckillId: seckillId,
          goodsId: goodsId,
          type: 4,
          sales,
          stock,
          isRemind,
        },
      });
    },
    async reserveBtn(id, index) {
      const res = await setRemind({ seckill_id: id });
      if (res.code * 1 === 1) {
        Toast.success("预约成功");
        // this.$emit("touchClick");
        this.goods_list[index].is_remind = 1;
      }
    },
    twoNum(num) {
      // 获取两位小数
      return (num * 1).toFixed(2);
    },
    onLoad() {
      // this.getGoodsList();
    },
    reloadGoods() {
      this.finished = false;
      this.total = 100;
      this.page = 1;
      this.goods_list = [];
      this.getGoodsList();
    },
    async getGoodsList() {
      if (this.total <= this.page || this.finished || this.isAjax) {
        return false;
      }
      this.isAjax = true;
      // 请求分流头
      const sendData = {
        group_id: this.groupId,
        class_three_id: this.classId,
        good_name: this.goodName,
        order_by: this.orderBy,
        page: this.page,
      };
      const res = await getSeckillList(sendData);
      console.log("=================", res);
      this.isAjax = false;
      if (res.code * 1 === 1) {
        ++this.page;
        this.goods_list = this.goods_list.concat(res.data.list);
        this.total = res.data.total;
        this.loading = false;
        if (this.goods_list.length >= this.total) {
          this.finished = true;
        }
      }
    },
    toInteger(num) {
      // 取整
      let toNum = "";
      toNum = parseInt(num);
      return toNum;
    },
    twoFloating(num) {
      // 获取两位小数
      return tools.twoFloating(num);
      // let price = "";
      // price = Math.floor(num * 100) / 100;
      // price = String(price).split(".")[1];
      // if (price !== undefined && price.length === 1) {
      //   price = `.${price}0`;
      // } else {
      //   price === undefined ? (price = ".00") : (price = `.${price}`);
      // }
      // // console.log("初始数据----", num, "-------------最终结果----", price);
      // return price;
    },
  },
};
</script>

<style lang="less" scoped>
img {
  display: block;
  width: 100%;
}
// 卡片上划线 + 卡片边角
.card-border {
  border-top: 1px solid #ededed;
  border-radius: 0 0 16px 16px !important;
}
.goods-list-card {
  display: flex;
  background-color: #fff;
  border-radius: 16px;
  margin-bottom: 12px;
  padding: 12px;
  .goods-img {
    flex-shrink: 0;
    width: 130px;
    img {
      width: 130px;
      height: 130px;
      border-radius: 6px;
    }
  }
  .detail-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 10px;
    width: 100%;
    .goods-name {
      width: 80%;
      font-size: 16px;
    }
    .progress {
      display: flex;
      justify-content: space-between;
      padding-top: 8px;
      font-size: 13px;
    }
    .price {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .present-price {
        color: #ed301d;
        font-size: 14px;
        .to-integer {
          font-size: 18px;
        }
      }
      .original-price {
        color: #d5d5d5;
        text-decoration: line-through;
        font-size: 12px;
        padding-left: 4px;
      }
    }
    .time-limit {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70px;
      padding: 2px 0;
      font-size: 12px;
      border-radius: 6px;
      color: #ed301d;
      background-color: #feebec;
      .lightning-img {
        width: 12px;
        padding-left: 4px;
      }
    }
  }
}
// vant 按钮样式
.van-button--small {
  box-shadow: 0px 2px 4px rgba(255, 0, 0, 0.16);
  padding: 0 14px;
}
/deep/.van-button__text {
  font-size: 14px;
}
</style>
